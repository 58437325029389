<template>
  <div class="content">
    <article class="row" style="background: #1f1e1f ;">
      <section class="text-center col-lg-8 col-sm-12">


        <HeadImage :image="clubImage" align="text-center" :title="club.title"
                   text-transform="uppercase"
                   :color="clubColor"
                   :caption="this.caption"></HeadImage>


        <div v-if="incomingClubParties.length ">

          <img
            v-lazy="this.logo + '?w=40w=40&fit=clamp'"
            height="40px"
            width="40px"
            style="border-radius: 100px; margin-right: -20px; z-index: 2"
          />
          <img
            v-lazy="
              incomingClubParties[0].metadata.main_image.imgix_url +
              '?w=40w=40&fit=clamp'
            "
            :alt="incomingClubParties[0].title"
            height="40px"
            width="40px"
            style="border-radius: 100px;border: 2px solid black"
          />
          <a
            style="margin-left: 5px"
            class="text-left d-inline-lg "
            v-if="incomingClubParties.length"
            href="#incoming-events"
          >Upcoming Events
            ({{ incomingClubParties.length }})
          </a>

        </div>

        <div
          class="card text-center col-md-12 col-md-10 offset-md-1"
          style="z-index: 1; margin: 2px;min-height: 500px"
        >
          <div class="card">


            <div>
              <div class="card" >
                <div class="card" style="padding: 30px" >
                  <IncomingMiniParty
                    :club="club"
                    v-if="incomingClubParties"
                    :color="clubColor"
                    :incoming-party="incomingClubParties ? incomingClubParties.find(x => x.slug === this.$route.params.slug) : [{}]"
                    :logo="this.logo"></IncomingMiniParty>



                </div>


              </div>
            </div>
          </div>


          <UpcomingEvents :logo="this.logo" :club-color="clubColor" :club-config="clubConfig" size="lg"
                          :incoming-club-parties="incomingClubParties" />

          <div style="margin-top: 35px" class="text-left"
          >
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />{{ club.title }}

            </h3>

            <p style="margin-left: 60px;font-size: 0.6rem;margin-top: -45px"
            > ABOUT THE CLUB
            </p>

            <br>

            <div
              class="text-left"
              v-html="club.content"
              style="
                color: #fff !important;
                margin-bottom: 1px;
                line-height: 1.4rem;
              "
            ></div>
          </div>

          <div id="carrousel" style="margin-top: 30px">
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />
              GALLERY
            </h3>
            <Carrousel :folder="'clubs-'+this.clubSlug" :color="this.secondaryColor"></Carrousel>
          </div>

          <div v-if="this.video" style="margin-top: 35px"
               :style="{ backgroundImage: `linear-gradient(to bottom, ${this.clubColor}, #050404)`, }">
            <h3 class="text-left section-header">
              <img
                v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
                height="40px"
                width="40px"
                style="border-radius: 300px;margin-right: 10px;"
              />ACTION
            </h3>
            <hr
              style="height: 2px; margin-top: -10px"
              :style="{ backgroundColor: this.clubColor }"
            />
            <club-video-album :video="this.video"></club-video-album>
          </div>
          <div class="card" style="margin-top: 45px;"
          >
            <h3
              style="
                margin-bottom: 0;
                margin-top: 30px;
                color: #ffffff !important;
                font-weight: bold;
                font-size: 1.3rem;
              "
            >
              📲 INFO & RSVP
            </h3>
            <hr />
            <p>
              Secure your spot at <strong>{{ club.title }}</strong>.<br> Tables & Reservations
            </p>
            <br>
            <Reservations :identifier="club.metadata.code" :color="this.clubColor"></Reservations>
            <small>We operate from 9AM - 10PM / Answer within 15 mins</small>

          </div>
          <br />
        </div>

        <br />

        <br />

      </section>

      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <br>
        <!--        <h4 class="section-header" style="font-weight: bold">-->
        <!--          <i class="tim-icons icon-chat-33" style="padding: 5px;border-radius: 100px;"-->
        <!--             :style="`{border: '1px solid ${this.secondaryColor}'`"-->
        <!--          ></i> INFO & RSVP-->
        <!--        </h4>-->
        <h4
          class="text-center tulum-party-title-alt"
          style="font-weight: bold; font-size: 1.3rem"
          :style="{ backgroundColor: this.secondaryColor}"
        >
          TABLES & RSVP
        </h4>


        <div class="row">
          <div class="col-sm-12">
            <p class="text-center">
              Have questions or need more details? <strong>We're here to help!</strong>
            </p>
            <br>
          </div>

          <div class="col-sm-12 text-center">
            <img v-lazy="astroImg + '?h=30'" height="30px" class="text-center d-none d-xl-inline"
                 style="margin-bottom: 30px" />


            <br>
            <RsvpViaDialog
              :club-config="this.rsvpConfig"
              text="  📲 INFO & RSVP"
            ></RsvpViaDialog>
            <br>
            <small style="margin-top: 15px">TALK WITH US</small>

          </div>
        </div>

        <br>
        <br>
        <br>

        <UpcomingEvents :logo="this.logo" :club-color="clubColor" :club-config="clubConfig"
                        :incoming-club-parties="incomingClubParties" />
        <ClubsSmartMenu :links="links" :primary-color="this.clubColor" :secondary-color="this.secondaryColor">
        </ClubsSmartMenu>

      </aside>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Reservations from '../../components/Reservations/Reservations';
import clubsConfig from '../../common/clubsConfig';
import clubsTableConfig from '../../common/clubsTableConfig';
import ClubDetails from '@/components/Clubs/ClubDetailsTable';
import ClubMapTable from '@/components/Clubs/ClubMapTable';
import ClubVideoAlbum from '@/components/VideoAlbum/ClubVideoAlbum';
import ClubFaqs from '@/components/Clubs/ClubFaqs';
import Carrousel from '@/components/Carrousel';
import ClubsSmartMenu from '@/components/SmartMenu/ClubsSmartMenu';
import UpcomingEvents from '@/components/UpcomingEvents/UpcomingEvents';
import HeadImage from '@/components/HeadImage/HeadImage';
import IncomingParty from '@/components/UpcomingEvents/IncomingParty';
import { slugUtils } from '@/common/slugUtils';
import IncomingMiniParty from '@/components/UpcomingEvents/IncomingMiniParty';

import { slugDecoder, venueDecoder } from '@/common/Decoder';
import ReservationsCard from '@/pages/Venues/ReservationsCard';

const RsvpViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpViaDialog'
    );

export default {
  name: 'ClubEvent',
  props: ['slug', 'clubSlug'],
  mixins: [slugUtils],
  metaInfo() {
    const decoder = venueDecoder('@' + this.clubSlug);
    const eventTitle = this.getEventTitleFromSlug(this.slug);

    const ClubName = decoder.title;
    const title = `${ClubName} | ${eventTitle}`;
    const clubDescription = decoder.desc || 'Tulum\'s exclusive nightclub. Reserve  tables & get tickets for a premium nightlife experience.';
    return {
      title: `${title}`,
      meta: [
        {
          name: 'description',
          content: `${clubDescription}`
        },
        {
          name: 'keywords',
          content: `Tulum, Clubs, Nightclubs, ${this.club.title} party, reservations, bookings, club, nightclub`
        },
        {
          property: 'og:title',
          content: `${title}`
        },
        {
          property: 'og:url',
          content: `https://tulum.party/tulum-clubs/${this.clubSlug}/event/${this.slug}`
        },
        {
          property: 'og:description',
          content: `${clubDescription}`
        },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:image',
          content: this.clubImage
        }
      ]
    };
  },
  data: () => {
    return {
      showAll: false,
      showDetailsTab: true,
      showFaqsTab: false,
      showLocationTab: false,
      showReviewTab: false,
      video: false,
      showPalms: false,
      secondaryColor: '',
      logo: '',
      clubStyle: '',
      clubConfig: {},
      rsvpConfig: {},
      clubTagsConfig: {},
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      astroImg:
        'https://imgix.cosmicjs.com/6ed6b0f0-60cd-11ee-b3cd-9debaa756aee-image.png',
      bgPalm:
        'https://imgix.cosmicjs.com/0e9bd970-f811-11ec-b2b1-473235369c53-image.png?q=&w=800&h=500&fit=clamp&h=160&h&duotone=aquamarine,FA8072&duotone-alpha=100',
      clubColor: 'aquamarine'
    };
  },
  methods: {
    showTab(tab) {
      if (tab === 'location') {
        this.showLocationTab = true;
        this.showDetailsTab = false;
        this.showReviewTab = false;
        this.showFaqsTab = false;
      }
      if (tab === 'details') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = true;
        this.showFaqsTab = false;
      }
      if (tab === 'faqs') {
        this.showLocationTab = false;
        this.showReviewTab = false;
        this.showDetailsTab = false;
        this.showFaqsTab = true;
      }
    }
  },
  components: {
    IncomingMiniParty,
    HeadImage,
    UpcomingEvents,
    ClubsSmartMenu,
    Carrousel,
    RsvpViaDialog,
    Reservations,
    ClubVideoAlbum
  },
  computed:
    mapState({
      incomingClubParties: (state) => {
        return state.status.loading ? state.loadingParties.slice(0, 6) : (state.incomingClubParties[state.venue.slug] || []);
      },
      club: (state) => state.venue,
      clubs: (state) => state.venues,
      clubImage: (state) =>
        state.venue.metadata ? state.venue.metadata.main_image.imgix_url : '',
      links: (state) => state.links
    }),
  created() {


    this.clubConfig = clubsConfig[this.clubSlug] || { color: '#313742', bgPalm: '', video: '', code: '' };

    if (!this.clubConfig) {
      this.$rollbar.error('Tulum Clubs - Invalid Slug', this.clubSlug);
      this.$router.push({ path: '/tulum-clubs', replace: true });
      return;
    }

    this.clubTagsConfig = clubsTableConfig[this.clubSlug];
    this.clubColor = this.clubConfig.color;
    this.caption = this.clubConfig.caption || 'Tulum Top Clubs';
    this.params = this.$route.params;
    this.secondaryColor = this.clubConfig.secondaryColor;
    this.logo = this.clubConfig.logo;
    this.bgPalm = this.clubConfig.bgPalm;
    this.video = this.clubConfig.video;
    this.rsvpConfig = {
      name: this.clubConfig.name.toLowerCase().replace(/tulum/, ''),
      logo: this.logo + '?w=60&h=60&fit=clamp&auto=format',
      primaryColor: this.clubColor, secondaryColor: this.secondaryColor
    };
    this.$store.dispatch('getVenue', { slug: this.clubSlug });
    this.$store.dispatch('getLinks', { slug: this.clubSlug });

    this.$store.dispatch('getClubParties', { slug: slugDecoder(this.clubSlug) });
  }
};
</script>
